import React, { ReactElement } from "react";

export const appendComponentWithNoBr = (
    text: string | undefined,
    component: ReactElement,
): ReactElement => {
    if (!text) return <></>;

    // Issues with soft hyphens in mobile, so using default behavior until solved in the future.
    // return (
    //     <NoBr>
    //         <NoBrText
    //             dangerouslySetInnerHTML={{
    //                 __html: text,
    //             }}
    //         />
    //         &nbsp;{component}
    //     </NoBr>
    // );
    return (
        <span>
            <span
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
            &nbsp;{component}
        </span>
    );
};
