import { styled, css } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import { default as NavLink } from "components/Link";
import {
    styleBodyM,
    styleHeadingM,
    styleLabelM,
    styleLabelS,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const textColor = theme("contentTheme", {
    black: "--text-on-neutral-primary",
    cyan: "--text-on-blue-primary",
    blue: "--text-on-cyan-primary",
    white: "--text-on-image-primary",
});

const linkColor = theme("contentTheme", {
    black: "--text-on-neutral-secondary",
    cyan: "--text-on-blue-secondary",
    blue: "--text-on-cyan-secondary",
    white: "--text-on-image-primary",
});

const hoverColor = theme("contentTheme", {
    black: "--text-on-neutral-link",
    cyan: "--text-on-blue-primary-alt",
    blue: "--text-on-cyan-primary",
    white: "--text-on-image-primary",
});

const headingHoverColor = theme("variant", {
    default: textColor,
    slim: hoverColor,
});

const borderColor = theme("contentTheme", {
    black: "--line-on-neutral-default",
    cyan: "--line-on-blue-default",
    blue: "--line-on-cyan-default",
    white: "--line-on-image-default",
});

const iconColor = theme("contentTheme", {
    black: "--icon-decorative-blue",
    cyan: "--icon-decorative-cyan",
    blue: "--icon-decorative-blue",
    white: "--text-on-image-primary",
});

export const Container = styled.div.attrs<{
    $hasIcon?: boolean;
    $isSlim?: boolean;
}>(() => ({}))`
    display: inline-flex;
    width: 100%;
    ${(props) =>
        !props.$hasIcon &&
        css`
            ${Link} {
                border-top: 1px solid var(${borderColor});
                flex-grow: 1;
                padding-top: 1.75rem;

                ${MQ.FROM_M} {
                    padding-top: 2.625rem;
                }

                ${MQ.FROM_XL} {
                    padding-top: 3.5rem;
                }
            }
        `}

    ${(props) =>
        props.$isSlim &&
        css`
            ${Link} {
                padding-top: 1.3125rem;
            }

            ${Heading} {
                margin-bottom: 0.4375rem;
            }

            ${Text} {
                margin-bottom: 0;
            }
        `}

    ${MQ.FROM_M} {
        ${(props) =>
            props.$isSlim &&
            css`
                ${Heading} {
                    margin-bottom: 0.875rem;
                }
            `}
    }

    ${MQ.FROM_XL} {
        ${(props) =>
            props.$isSlim &&
            css`
                ${Link} {
                    padding-top: 1.75rem;
                }
            `}
    }
`;

export const Label = styled.div`
    ${styleLabelS}
    color: var(${textColor});
    margin: 0;
`;

export const Heading = styled.div`
    ${styleHeadingM}
    color: var(${textColor});
    margin-bottom: 1.3125rem;
    margin-top: 0;
    transition: color 0.2s ease-in-out;

    span {
        color: var(${textColor});
        transition: color 0.2s ease-in-out;
    }
`;

export const HeadingArrow = styled(Icon)`
    color: var(${textColor});
    margin-left: 0.875rem;
    margin-right: 0.875rem;
    transition:
        margin-left 0.2s ease-in-out,
        margin-right 0.2s ease-in-out,
        color 0.2s ease-in-out;
`;

export const Text = styled.div`
    ${styleBodyM};
    color: var(${textColor});
    margin-bottom: 0.4375rem;
    ${theme("contentTheme", {
        white: css`
            filter: var(--shadow-on-image);
        `,
    })};
`;

export const LinkText = styled.div`
    ${styleLabelM};
    color: var(${linkColor});
    margin-right: 0.875rem;
    transition:
        margin-right 0.2s ease-in-out,
        color 0.2s ease-in-out;
    ${theme("contentTheme", {
        white: css`
            filter: var(--shadow-on-image);
        `,
    })};
`;

export const IconLarge = styled(Icon)`
    color: var(${iconColor});
    display: block;
    margin-bottom: 1.75rem;

    ${MQ.FROM_XL} {
        margin-bottom: 2.1875rem;
    }
`;

export const IconArrow = styled(Icon)`
    color: var(${linkColor});
    margin-right: 0.875rem;
    transition:
        margin-left 0.2s ease-in-out,
        color 0.2s ease-in-out;
`;

export const Link = styled(NavLink)`
    border-bottom: none;
    color: var(${textColor});
    display: block;

    &:hover {
        ${LinkText} {
            margin-right: 0;
            color: var(${hoverColor});
        }

        ${IconArrow} {
            color: var(${hoverColor});
            margin-left: 0.875rem;
        }

        ${Heading} {
            color: var(${headingHoverColor});

            span {
                color: var(${headingHoverColor});
            }
        }

        ${HeadingArrow} {
            color: var(${headingHoverColor});
            margin-left: 1.75rem;
            margin-right: 0;
        }
    }
`;
