import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import { useBreakpoint } from "hooks";
import { appendComponentWithNoBr } from "utils/appendComponentWithNoBr";

import {
    Container,
    Link,
    IconArrow,
    Heading,
    HeadingArrow,
    Text,
    LinkText,
    IconLarge,
    Label,
} from "./CallToAction.styled";
import CallToActionProps from "./CallToActionProps";

const CallToAction = ({
    className,
    contentTheme,
    identifier,
    label,
    heading,
    text,
    url,
    linkText,
    icon,
    target,
    gtmClickType = "cta",
}: CallToActionProps): ReactElement => {
    const variant = linkText ? "default" : "slim";

    let iconSize: string;
    switch (useBreakpoint(["XL"])) {
        case "XL":
            iconSize = "140";
            break;
        default:
            iconSize = "105";
    }
    return (
        <ThemeProvider theme={{ contentTheme: contentTheme, variant: variant }}>
            <Container
                id={identifier}
                key={identifier || `CallToActionContent`}
                className={className}
                $hasIcon={!!icon}
                $isSlim={!linkText}
            >
                <Link
                    to={url}
                    target={target}
                    exact
                    gtmClickType={gtmClickType}
                >
                    {icon && (
                        <>
                            <IconLarge
                                key={`IconLarge-${icon}-${iconSize || ""}`}
                                icon={`${icon}${iconSize}`}
                                aria-hidden="true"
                            />
                        </>
                    )}
                    {label && <Label>{label}</Label>}
                    <Heading>
                        {appendComponentWithNoBr(
                            heading,
                            !linkText ? (
                                <HeadingArrow
                                    icon={"longArrowRight28"}
                                    aria-hidden="true"
                                />
                            ) : (
                                <></>
                            ),
                        )}
                    </Heading>
                    {text && (
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: text,
                            }}
                        />
                    )}
                    {linkText && (
                        <LinkText>
                            <IconArrow
                                icon={"longArrowRight28"}
                                aria-hidden="true"
                            />
                            {linkText}
                        </LinkText>
                    )}
                </Link>
            </Container>
        </ThemeProvider>
    );
};

export default React.memo(CallToAction);
